import React from "react";
import {
  useGoCardlessDropin
} from "@gocardless/react-dropin";

let isOpened = false;

const App = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const flowId = params.get('flowId');
  const environment = params.get('environment') || 'live';


  console.log('QUERY PARAMS IS >> ', flowId, environment);

  const config = ({
    billingRequestFlowID: flowId,
    environment: environment,
    onSuccess: (billingRequest, billingRequestFlow) => {
      window?.ReactNativeWebView?.postMessage(JSON.stringify({ status: 'success', billingRequest, billingRequestFlow }));
    },
    onExit: (error, metadata) => {
      window?.ReactNativeWebView?.postMessage(JSON.stringify({ status: 'failed', error, metadata }));
    },    
  });

  const {
    open, exit, ready, error,
  } = useGoCardlessDropin(config);

  if (!flowId) {
    return <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>Id is missing, please try again.</div>
  }

  if (ready && !isOpened) {
    console.log('IS OPENED >> ', isOpened);
    isOpened = true;
    open();
  }

  return <div className="" style={{ flex: 1, display: 'flex', flexDirection: 'column', height: '100vh', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
    Loading..
  </div>

}

export default App;
